<template>
  <div>
    <div class="title">开票明细</div>
    <Padding />
    <a-alert :message="invoiceMessage" type="info" show-icon />
    <Padding />

    <a-table
      bordered
      :data-source="invoiceList"
      :pagination="false"
      :loading="loading"
      rowKey="id"
    >
      <a-table-column title="发票号码" data-index="invoiceCode" />
      <a-table-column title="业务内容" data-index="business" />
      <a-table-column
        title="开票日期"
        data-index="invoiceDate"
        align="center"
      />
      <a-table-column title="发票金额(元)" align="right">
        <template slot-scope="text">
          <span
            v-if="parseFloat(text.totalPriceLowercase) < 0"
            style="color: #f00"
            >{{ parseFloat(text.totalPriceLowercase).toLocaleString() }}</span
          >
          <span v-else style="color: #1890ff">{{
            parseFloat(text.totalPriceLowercase).toLocaleString()
          }}</span>
        </template>
      </a-table-column>
      <a-table-column title="当日余额(元)" align="right">
        <template slot-scope="text">
          <span v-if="typeof text.sumRemainingThisday === 'number'">
            <span v-if="text.sumRemainingThisday < 0" style="color: #f00">{{
              text.sumRemainingThisday.toLocaleString()
            }}</span>
            <span v-else style="color: #1890ff">{{
              text.sumRemainingThisday.toLocaleString()
            }}</span>
          </span>
        </template>
      </a-table-column>

      <a-table-column title="回款计划" align="center">
        <template slot-scope="text">
          <DataDictFinder
            dictType="account_collect_status"
            :dictValue="text.receivableStatus"
            iconType="text"
          />
        </template>
      </a-table-column>

      <a-table-column title="操作" align="center">
        <template slot-scope="text">
          <a
            href="#"
            @click.prevent="
              $router.push('/finance/invoice?invoiceCode=' + text.invoiceCode)
            "
            >查看</a
          >
        </template>
      </a-table-column>
    </a-table>

    <Padding />
    <div class="title">回款明细</div>
    <Padding />

    <a-alert
      style="margin-bottom: 8px"
      :message="`回款总额：${
        typeof info.paymentSum === 'number'
          ? info.paymentSum.toLocaleString()
          : '--'
      } 元`"
      type="info"
      show-icon
    />

    <a-table
      bordered
      :data-source="paymentList"
      :pagination="false"
      :loading="loading"
      rowKey="id"
    >
      <a-table-column title="回款金额(元)" align="center">
        <template slot-scope="text">
          <span v-if="parseFloat(text.account) < 0" style="color: #f00">{{
            parseFloat(text.account).toLocaleString()
          }}</span>
          <span v-else style="color: #1890ff">{{
            parseFloat(text.account).toLocaleString()
          }}</span>
        </template>
      </a-table-column>

      <a-table-column title="回款日期" align="center" data-index="createAt" />
      <a-table-column
        title="回款发票"
        align="center"
        data-index="invoiceCode"
      />

      <a-table-column title="操作" align="center">
        <template slot-scope="text">
          <a
            href="#"
            @click.prevent="
              $router.push(
                '/finance/accounts-receivable?invoiceCode=' + text.invoiceCode
              )
            "
            >查看</a
          >
        </template>
      </a-table-column>
    </a-table>

    <Padding />
    <div class="title">收款项明细</div>
    <Padding />

    <a-alert
      style="margin-bottom: 8px"
      :message="receiptMessage"
      type="info"
      show-icon
    />

    <a-table
      bordered
      :data-source="receiptList"
      :pagination="false"
      :loading="loading"
      rowKey="id"
    >
      <a-table-column
        title="编号"
        data-index="code"
        align="center"
        width="50px"
      />
      <a-table-column title="收款项名称" data-index="name" />
      <a-table-column title="收款金额(元)" align="right">
        <template slot-scope="text">
          <span v-if="parseFloat(text.planReceipt) < 0" style="color: #f00">{{
            parseFloat(text.planReceipt).toLocaleString()
          }}</span>
          <span v-else style="color: #1890ff">{{
            parseFloat(text.planReceipt).toLocaleString()
          }}</span>
        </template>
      </a-table-column>
      <a-table-column title="收款比例(%)" data-index="ratio" align="right" />
      <a-table-column title="是否确认" align="center">
        <template slot-scope="text">
          <a-checkbox :checked="text.isFinished === 1" />
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>


<script>
import { fetchBill } from "@/api/project/overview";
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    selectedContractId: {},
  },

  data() {
    return {
      info: {},
      list: [],
      loading: false,

      selectedRowKeys: [],
    };
  },
  mounted() {
    this.getData();
  },

  watch: {
    selectedContractId() {
      this.getData();
    },
  },

  computed: {
    invoiceList() {
      return Array.isArray(this.info.invoiceList) ? this.info.invoiceList : [];
    },
    invoiceMessage() {
      return `发票总额：${
        typeof this.info.invoiceSum === "number"
          ? this.info.invoiceSum.toLocaleString()
          : "--"
      } 元；   当日余额：${
        typeof this.info.sumRemainingThisdaySum === "number"
          ? this.info.sumRemainingThisdaySum.toLocaleString()
          : "--"
      } 元`;
    },
    paymentList() {
      return Array.isArray(this.info.paymentList) ? this.info.paymentList : [];
    },
    receiptList() {
      return Array.isArray(this.info.receiptList) ? this.info.receiptList : [];
    },
    receiptMessage() {
      const list = this.receiptList.filter((item) => item.isFinished === 1);
      let ratio = 0;
      let money = 0;
      list.forEach((item) => {
        if (typeof item.ratio === "number") {
          ratio += item.ratio;
        }
        if (typeof item.planReceipt === "number") {
          money += item.planReceipt;
        }
      });

      return `已确认 ${
        list.length
      } 项； 总确认度 ${ratio} %； 确认金额：${money.toLocaleString()} 元`;
    },
  },

  methods: {
    getData() {
      let id = this.selectedContractId ? this.selectedContractId : "";
      if (!id) return;
      this.loading = true;
      fetchBill({
        id,
      })
        .then((res) => {
          this.info = res ?? {};
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(keys) {
      this.selectedRowKeys = keys;
    },

    openDetail() {
      this.$router.push(
        "/produce/contract/detail?id=" + this.detail.id + "&active=subpackage"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-weight: bold;
  font-size: 13px;
}
</style>