<template>
  <div>
    <div v-for="item in list" :key="item.id">
      <div class="between">
        <div class="title">
          {{ item.name }}
        </div>
        <a class="left" href="#" @click.prevent="$router.push('/produce/progress/detail?id=' + item.id)
                  ">
          <span style="padding-right: 2px">查看详情</span>
          <a-icon type="right" />
        </a>
      </div>
      <Padding />

      <a-table bordered :data-source="Array.isArray(item.projectProgressList)
                ? item.projectProgressList
                : []
              " :pagination="false" rowKey="id">
        <a-table-column title="阶段名称" data-index="phaseName" />
        <a-table-column title="占比" align="right">
          <template slot-scope="text"> {{ text.phasePercent }}% </template>
        </a-table-column>
        <a-table-column title="阶段进度" align="right">
          <template slot-scope="text"> {{ text.progress }}% </template>
        </a-table-column>

        <a-table-column title="阶段进度审核状态" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="taskReviewStatus" :dictValue="text.phaseProgressStatus" iconType="badge" />
          </template>
        </a-table-column>

        <a-table-column title="区域打分" align="right">
          <template slot-scope="text">
            <span style="color: #1890ff">
              {{ text.score * 100 }}
            </span>
          </template>
        </a-table-column>

        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a href="#" @click.prevent="seeFile(text)">查看确认资料</a>
          </template>
        </a-table-column>
      </a-table>
    </div>

    <a-modal title="确认资料" :visible="visible" :footer="null" @cancel="cancel">
      <a-table bordered :data-source="Array.isArray(activeText.outputList) ? activeText.outputList : []
              " :pagination="false">
        <a-table-column title="资料类型">
          <template slot-scope="text">
            {{ getFileType(text.type) }}
          </template>
        </a-table-column>
        <a-table-column title="文件名称">
          <template slot-scope="text">
            <a download target="_blank" :href="text.filePath">{{
                          text.fileName ? text.fileName : "--"
                          }}</a>
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { fetchProgress } from "@/api/project/overview";
import { mapGetters } from "vuex";
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    selectedContractId: {},
  },

  data() {
    return {
      list: [],
      selectedRowKeys: [],

      activeText: {},
      visible: false,
    };
  },

  mounted() {
    this.getData();
  },

  watch: {
    selectedContractId() {
      this.getData();
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    fileTypeList() {
      return this.findDataDict("fileType");
    },

    message() {
      let sum = 0;
      this.selectedRowKeys.forEach((id) => {
        const item = this.list.find((item) => item.id === id);
        if (item && typeof item.contractAmount === "number") {
          sum += item.contractAmount;
        }
      });
      return `已选择 ${this.selectedRowKeys.length} 项，合同金额为 ${parseFloat(
        (sum / 10000).toFixed(2)
      ).toLocaleString()} 万`;
    },
  },
  methods: {
    getData() {
      let id = this.selectedContractId ? this.selectedContractId : "";
      if (!id) return;
      this.loading = true;
      fetchProgress({
        id,
      })
        .then((res) => {
          if (Array.isArray(res)) {
            this.list = res;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(keys) {
      this.selectedRowKeys = keys;
    },

    seeFile(text) {
      this.visible = true;
      this.activeText = text;
    },
    cancel() {
      this.visible = false;
    },
    getFileType(type) {
      const typeObj = this.fileTypeList.find(
        (item) => item.value === this.activeText.phaseCode
      );
      if (typeObj && Array.isArray(typeObj.children)) {
        const result = typeObj.children.find((item) => item.value === type);
        return result ? result.name : "--";
      } else {
        return "--";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-weight: bold;
  font-size: 13px;
}
</style>