<template>
  <div>
    <Pane :title="detail.name" />

    <div class="container">
      <div class="card">
        <Info :detail="detail" />
      </div>

      <div class="card" style="padding-top: 0">
        <a-tabs default-active-key="1">
          <a-tab-pane key="1" tab="数据看板">
            <div class="left" style="margin-bottom: 12px; margin-top: 8px">
              <span style="margin-right: 4px; width: 3em">合同</span>
              <a-select v-model="selectedContractId" style="width: 320px">
                <a-select-option v-for="item in detail.contractList ? detail.contractList : []" :key="item.id"
                  :value="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </div>

            <Dashboard :detail="detail" :selectedContractId="selectedContractId" />
          </a-tab-pane>
          <a-tab-pane key="2" tab="合同">
            <Contract :detail="detail" />
          </a-tab-pane>
          <a-tab-pane key="3" tab="分包">
            <div class="left" style="margin-bottom: 12px; margin-top: 8px">
              <span style="margin-right: 4px; width: 3em">合同</span>
              <a-select v-model="selectedContractId" style="width: 320px">
                <a-select-option v-for="item in detail.contractList ? detail.contractList : []" :key="item.id"
                  :value="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </div>

            <Subpackage :detail="detail" :selectedContractId="selectedContractId" />
          </a-tab-pane>
          <a-tab-pane key="4" tab="财务">
            <div class="left" style="margin-bottom: 12px; margin-top: 8px">
              <span style="margin-right: 4px; width: 3em">合同</span>
              <a-select v-model="selectedContractId" style="width: 320px">
                <a-select-option v-for="item in detail.contractList ? detail.contractList : []" :key="item.id"
                  :value="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </div>

            <Bill :detail="detail" :selectedContractId="selectedContractId" />
          </a-tab-pane>
          <a-tab-pane key="5" tab="进度">
            <div class="left" style="margin-bottom: 12px; margin-top: 8px">
              <span style="margin-right: 4px; width: 3em">合同</span>
              <a-select v-model="selectedContractId" style="width: 320px">
                <a-select-option v-for="item in detail.contractList ? detail.contractList : []" :key="item.id"
                  :value="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </div>

            <Progress :detail="detail" :selectedContractId="selectedContractId" />
          </a-tab-pane>
          <a-tab-pane key="6" tab="产值分配">
            <div class="left" style="margin-bottom: 12px; margin-top: 8px">
              <span style="margin-right: 4px; width: 3em">合同</span>
              <a-select v-model="selectedContractId" style="width: 320px">
                <a-select-option v-for="item in detail.contractList ? detail.contractList : []" :key="item.id"
                  :value="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </div>

            <Statistic :detail="detail" :selectedContractId="selectedContractId" />
          </a-tab-pane>

          <a-tab-pane key="file" tab="附件">
            <Files :detail="detail" />
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";

import Info from "./components/info.vue";
import Dashboard from "./components/dashboard.vue";
import Contract from "./components/contract.vue";
import Subpackage from "./components/subpackage.vue";
import Bill from "./components/bill.vue";
import Progress from "./components/progress.vue";
import Statistic from "./components/statistic/index.vue";
import Files from "./components/files.vue";
import { fetchDetail } from "@/api/project/overview";

export default {
  mixins: [watermark],

  components: {
    Info,
    Dashboard,
    Contract,
    Subpackage,
    Bill,
    Progress,
    Statistic,
    Files,
  },

  data() {
    return {
      detail: {},

      selectedContractId: null,
    };
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};

    fetchDetail({
      id,
    }).then((res) => {
      this.detail = res;
      this.selectedContractId = id;
    });
  },
};
</script>

<style lang="less" scoped>
.card {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 8px;
}
</style>