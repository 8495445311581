<template>
  <div>
    <input
      ref="input"
      id="upload"
      type="file"
      @change="onChange"
      :multiple="multiple"
    />

    <div @click="onClick">
      <slot />
      <div v-if="uploadType === 'image'" class="image-upload">
        <img v-if="imagePath" :src="imagePath" alt="图片" class="image" />
        <div v-else class="uploader">
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="text">上传</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uploadFile } from "@/api/upload";
export default {
  props: {
    uploadType: {
      type: String,
      default: "file",
    },
    imagePath: {
      type: String,
      default: "",
    },
    // 是否可以多选
    multiple: {
      type: Boolean,
      default: true,
    },
    // 要上传的位置
    moduleName: {
      type: String,
      default: "project",
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    onClick() {
      this.$refs.input.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("files", files[i]);
        data.append("moduleName", this.moduleName);
      }

      const hide = this.$message.loading("上传中...", 0);
      this.loading = true;

      uploadFile(data)
        .then((res) => {
          console.log("upload res", res);
          if (Array.isArray(res)) {
            this.$emit(
              "uploaded",
              res.map((item) => {
                return {
                  id: item.id,
                  name: item.name,
                  completePath: item.completePath,
                };
              })
            );
          }
        })
        .finally(() => {
          hide();
          this.loading = false;
          e.target.value = null;
        });
    },
  },
};
</script>

<style lang="less" scoped>
#upload {
  display: none;
}

.image-upload {
  cursor: pointer;
  .uploader {
    width: 128px;
    height: 128px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    i {
      font-size: 32px;
      color: #999;
    }
    .text {
      color: #666;
    }
  }
}

.image {
  width: 128px;
  height: 128px;
}
</style>