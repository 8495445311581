var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cards"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"header"},[_c('a-icon',{staticStyle:{"color":"#1890ff","font-size":"18px"},attrs:{"type":"caret-right"}}),_c('div',{staticClass:"title"},[_vm._v("项目进度+阶段")])],1),(_vm.loading)?_c('a-skeleton'):_c('div',[_c('div',{staticClass:"statistic"},[_c('div',[_c('div',{staticClass:"label"},[_vm._v("项目阶段")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.info.projectProgress ? _vm.info.projectProgress.phaseName : "--"))])]),_c('div',[_c('div',{staticClass:"label"},[_vm._v("占比")]),_c('div',[_c('span',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.info.projectProgress ? _vm.info.projectProgress.phasePercent : "--")+" ")]),_c('span',[_vm._v("%")])])]),_c('div',[_c('div',{staticClass:"label"},[_vm._v("阶段进度审核状态")]),_c('div',{staticClass:"value"},[(_vm.info.projectProgress)?_c('span',[_c('DataDictFinder',{attrs:{"dictType":"taskReviewStatus","dictValue":_vm.info.projectProgress.phaseProgressStatus,"iconType":"text"}})],1):_vm._e()])]),_c('div',[_c('div',{staticClass:"label"},[_vm._v("区域打分")]),_c('div',{staticClass:"value"},[(_vm.info.projectProgress)?_c('span',[_vm._v(" "+_vm._s(_vm.info.projectProgress.score ? _vm.info.projectProgress.score * 100 : "--")+" ")]):_vm._e()])]),_c('div',[_c('div',{staticClass:"label"},[_vm._v("审批人")]),_c('div',{staticClass:"value"},[(_vm.info.projectProgress)?_c('span',[_vm._v(" "+_vm._s(_vm.info.projectProgress.phaseProgressAssignerName ? _vm.info.projectProgress.phaseProgressAssignerName : "--")+" ")]):_vm._e()])])]),_c('div',{staticClass:"center"},[_c('Pie',{attrs:{"id":"phase-progress","title":"项目进度","list":[
                        {
                          name: '1',
                          value: typeof _vm.info.progress === 'number' ? _vm.info.progress : 0,
                        },
                        {
                          name: '2',
                          value:
                            typeof _vm.info.progress === 'number' ? 100 - _vm.info.progress : 0,
                        },
                      ]}})],1)])],1),_c('div',{staticClass:"card"},[_c('div',{staticClass:"header"},[_c('a-icon',{staticStyle:{"color":"#1890ff","font-size":"18px"},attrs:{"type":"caret-right"}}),_c('div',{staticClass:"title"},[_vm._v("收款项确认进度")])],1),(_vm.loading)?_c('a-skeleton'):_c('div',[_c('div',{staticClass:"statistic"},[_c('div',[_c('div',{staticClass:"label"},[_vm._v("合同金额")]),_c('div',[_c('span',{staticClass:"value"},[_c('Money',{attrs:{"money":_vm.info.contractAmount}})],1),_c('span',[_vm._v("万")])])]),_c('div',[_c('div',{staticClass:"label"},[_vm._v("进度")]),_c('div',[_c('span',{staticClass:"value"},[_vm._v(" "+_vm._s(typeof _vm.info.receiptRatioSum === "number" ? _vm.info.receiptRatioSum : "--")+" ")]),_c('span',[_vm._v("%")])])])]),_c('div',{staticClass:"center"},[_c('Pie',{attrs:{"id":"income-progress","title":"进度","list":[
                        {
                          name: '1',
                          value:
                            typeof _vm.info.receiptRatioSum === 'number'
                              ? _vm.info.receiptRatioSum
                              : 0,
                        },
                        {
                          name: '2',
                          value:
                            typeof _vm.info.receiptRatioSum === 'number'
                              ? 100 - _vm.info.receiptRatioSum
                              : 0,
                        },
                      ],"color":['#6AD389', '#F0F2F5']}})],1)])],1),_c('div',{staticClass:"card"},[_c('div',{staticClass:"header"},[_c('a-icon',{staticStyle:{"color":"#1890ff","font-size":"18px"},attrs:{"type":"caret-right"}}),_c('div',{staticClass:"title"},[_vm._v("合同分包情况")])],1),(_vm.loading)?_c('a-skeleton'):_c('div',[_c('div',{staticClass:"statistic"},[_c('div',[_c('div',{staticClass:"label"},[_vm._v("合同金额")]),_c('div',[_c('span',{staticClass:"value"},[_c('Money',{attrs:{"money":_vm.info.contractAmount}})],1),_c('span',[_vm._v("万")])])]),_c('div',[_c('div',{staticClass:"label"},[_vm._v("分包总额")]),_c('div',[_c('span',{staticClass:"value"},[_c('Money',{attrs:{"money":_vm.info.subSum}})],1),_c('span',[_vm._v("万")])])]),_c('div',[_c('div',{staticClass:"label"},[_vm._v("设计类-必要分包")]),_c('div',[_c('span',{staticClass:"value"},[_c('Money',{attrs:{"money":_vm.info.subNeedSum}})],1),_c('span',[_vm._v("万")])])]),_c('div',[_c('div',{staticClass:"label"},[_vm._v("设计类-非必要分包")]),_c('div',[_c('span',{staticClass:"value"},[_c('Money',{attrs:{"money":_vm.info.subNotNeedSum}})],1),_c('span',[_vm._v("万")])])]),_c('div',[_c('div',{staticClass:"label"},[_vm._v("总承包类分包")]),_c('div',[_c('span',{staticClass:"value"},[_c('Money',{attrs:{"money":_vm.info.subZcbSum}})],1),_c('span',[_vm._v("万")])])])]),_c('div',{staticClass:"center"},[_c('Pie',{attrs:{"id":"subpackage1","title":"分包","list":[
                        {
                          name: '1',
                          value:
                            typeof _vm.info.subSum === 'number'
                              ? Math.round((_vm.info.subSum / _vm.info.contractAmount) * 100)
                              : 0,
                        },
                        {
                          name: '2',
                          value:
                            typeof _vm.info.subSum === 'number'
                              ? 100 -
                              Math.round((_vm.info.subSum / _vm.info.contractAmount) * 100)
                              : 0,
                        },
                      ],"color":['#1890ff', '#F0F2F5']}}),_c('Pie',{attrs:{"id":"subpackage2","title":"设计类-必要分包","list":[
                        {
                          name: '1',
                          value:
                            typeof _vm.info.subNeedSum === 'number'
                              ? Math.round((_vm.info.subNeedSum / _vm.info.contractAmount) * 100)
                              : 0,
                        },
                        {
                          name: '2',
                          value:
                            typeof _vm.info.subNeedSum === 'number'
                              ? 100 -
                              Math.round((_vm.info.subNeedSum / _vm.info.contractAmount) * 100)
                              : 0,
                        },
                      ],"color":['#6AD389', '#F0F2F5']}}),_c('Pie',{attrs:{"id":"subpackage3","title":"设计类-非必要分包","list":[
                        {
                          name: '1',
                          value:
                            typeof _vm.info.subNotNeedSum === 'number'
                              ? Math.round(
                                (_vm.info.subNotNeedSum / _vm.info.contractAmount) * 100
                              )
                              : 0,
                        },
                        {
                          name: '2',
                          value:
                            typeof _vm.info.subNotNeedSum === 'number'
                              ? 100 -
                              Math.round(
                                (_vm.info.subNotNeedSum / _vm.info.contractAmount) * 100
                              )
                              : 0,
                        },
                      ],"color":['#D3666C', '#F0F2F5']}}),_c('Pie',{attrs:{"id":"subpackage4","title":"总承包类分包","list":[
                        {
                          name: '1',
                          value:
                            typeof _vm.info.subZcbSum === 'number'
                              ? Math.round((_vm.info.subZcbSum / _vm.info.contractAmount) * 100)
                              : 0,
                        },
                        {
                          name: '2',
                          value:
                            typeof _vm.info.subZcbSum === 'number'
                              ? 100 -
                              Math.round((_vm.info.subZcbSum / _vm.info.contractAmount) * 100)
                              : 0,
                        },
                      ],"color":['#F5A747', '#F0F2F5']}})],1)])],1),_c('div',{staticClass:"card"},[_c('div',{staticClass:"header"},[_c('a-icon',{staticStyle:{"color":"#1890ff","font-size":"18px"},attrs:{"type":"caret-right"}}),_c('div',{staticClass:"title"},[_vm._v("合同净额&进度")])],1),(_vm.loading)?_c('a-skeleton'):_c('div',[_c('div',{staticClass:"statistic"},[_c('div',[_c('div',{staticClass:"label"},[_vm._v("合同净额")]),_c('div',[_c('span',{staticClass:"value"},[_c('Money',{attrs:{"money":_vm.info.contractAmount}})],1),_c('span',[_vm._v("万")])])]),_c('div',[_c('div',{staticClass:"label"},[_vm._v("开票")]),_c('div',[_c('span',{staticClass:"value"},[_c('Money',{attrs:{"money":_vm.info.invoiceSum}})],1),_c('span',[_vm._v("万")])])]),_c('div',[_c('div',{staticClass:"label"},[_vm._v("回款")]),_c('div',[_c('span',{staticClass:"value"},[_c('Money',{attrs:{"money":_vm.info.paymentSum}})],1),_c('span',[_vm._v("万")])])])]),_c('div',{staticClass:"progress-title"},[_vm._v("净额")]),_c('div',{staticClass:"left",staticStyle:{"color":"#f5a646"}},[_c('div',{staticClass:"progress-bg"},[_c('div',{staticClass:"progress",staticStyle:{"background-color":"#f5a646"},style:({
                            width:
                              _vm.getPercent(_vm.info.contractAmount, _vm.info.contractAmount) + '%',
                          })})]),_c('div',{staticClass:"progress-value"},[_vm._v(_vm._s(_vm.getPercent(_vm.info.contractAmount, _vm.info.contractAmount)))]),_c('div',[_vm._v("%")])]),_c('div',{staticClass:"progress-title",staticStyle:{"margin-top":"8px"}},[_vm._v("开票")]),_c('div',{staticClass:"left",staticStyle:{"color":"#1890ff"}},[_c('div',{staticClass:"progress-bg"},[_c('div',{staticClass:"progress",staticStyle:{"background-color":"#1890ff"},style:({
                        width: _vm.getPercent(_vm.info.invoiceSum, _vm.info.contractAmount) + '%',
                      })})]),_c('div',{staticClass:"progress-value"},[_vm._v(_vm._s(_vm.getPercent(_vm.info.invoiceSum, _vm.info.contractAmount)))]),_c('div',[_vm._v("%")])]),_c('div',{staticClass:"progress-title",staticStyle:{"margin-top":"8px"}},[_vm._v("回款")]),_c('div',{staticClass:"left",staticStyle:{"color":"#6ad389"}},[_c('div',{staticClass:"progress-bg"},[_c('div',{staticClass:"progress",staticStyle:{"background-color":"#6ad389"},style:({
                        width: _vm.getPercent(_vm.info.paymentSum, _vm.info.contractAmount) + '%',
                      })})]),_c('div',{staticClass:"progress-value"},[_vm._v(_vm._s(_vm.getPercent(_vm.info.paymentSum, _vm.info.contractAmount)))]),_c('div',[_vm._v("%")])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }