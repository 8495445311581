<template>
  <div class="info">
    <div class="table">
      <div class="label">设计编号</div>
      <div class="value">{{ detail.code }}</div>
      <div class="label">管理级别</div>
      <div class="value">
        <span v-if="detail.productHead === 1">总院项目</span>
        <span v-if="detail.productHead === 0">分院项目</span>
      </div>
      <div class="label">区域总经理</div>
      <div class="value">
        {{ detail.areaMasterName }}
      </div>
      <div class="label">生产院长</div>
      <div class="value">
        {{ detail.productionUnitMaster }}
      </div>
      <div class="label">生产负责人</div>
      <div class="value">
        {{ detail.productionMasterName }}
      </div>
      <div class="label">责任部门</div>
      <div class="value">
        {{ detail.productionUnitName }}
      </div>
    </div>

    <a target="_blank" v-if="activeImage" :href="activeImage">
      <img class="preview" :src="activeImage" alt="" />
    </a>

    <div class="images">
      <img
        v-for="(item, index) in images"
        :key="item.completePath"
        :src="item.completePath"
        alt=""
        @click="activeIndex = index"
        :class="activeIndex === index ? 'active' : ''"
      />
    </div>

    <div class="extra" v-if="detail.id">
      <FileUpload :multiple="false" @uploaded="uploaded">
        <a-button type="primary" :loading="loading">上传图片</a-button>
      </FileUpload>
    </div>
  </div>
</template>

<script>
import FileUpload from "@/components/file-upload";
import { updateImages } from "@/api/project/overview";

export default {
  components: {
    FileUpload,
  },

  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      images: [],
      activeIndex: 0,
      loading: false,
    };
  },

  computed: {
    activeImage() {
      const obj = this.images[this.activeIndex];
      return obj ? obj.completePath : "";
    },
  },

  mounted() {
    this.images = Array.isArray(this.detail.bannerList)
      ? this.detail.bannerList
      : [];
  },

  watch: {
    detail() {
      this.images = Array.isArray(this.detail.bannerList)
        ? this.detail.bannerList
        : [];
    },
  },

  methods: {
    uploaded(list) {
      const arr = [...this.images, ...list];
      this.loading = true;
      updateImages({
        id: this.detail.id,
        banner: arr.map((item) => item.id).join(","),
      })
        .then(() => {
          this.images = arr;
          this.activeIndex = arr.length - 1;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  display: flex;
  align-items: flex-start;
}
.table {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr;
  border: 1px solid #e8e8e8;
  background-color: #e8e8e8;
  gap: 1px;
  margin-right: 24px;
  height: 180px;

  .label,
  .value {
    padding: 0 16px;
    background-color: #fff;
    display: flex;
    align-items: center;
  }
  .label {
    background-color: #fafafa;
    justify-content: center;
  }
}
.preview {
  width: 320px;
  height: 180px;
  object-fit: cover;
  margin-right: 12px;
}

.images {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 180px;
  overflow-y: auto;

  img {
    cursor: pointer;
    width: 100px;
    height: 60px;
    object-fit: cover;
  }
  .active {
    border: 1px solid #1890ff;
  }
}

.extra {
  margin-left: auto;
}
</style>