<template>
  <div>
    <a-alert style="margin-bottom: 8px" v-if="selectedRowKeys.length > 0" :message="message" type="info" show-icon />

    <a-table bordered :data-source="list" :pagination="false" :loading="loading" rowKey="id" :row-selection="{
                selectedRowKeys,
                onChange: onChange,
              }">
      <a-table-column title="分包名称" data-index="name" />
      <a-table-column title="分包属性">
        <template slot-scope="text">
          <span v-if="text.isneeded === 1">设计类-必要分包</span>
          <span v-if="text.isneeded === 0">设计类-非必要分包</span>
          <span v-if="text.isneeded === 2">总承包类分包</span>
        </template>
      </a-table-column>
      <a-table-column title="分包类别">
        <template slot-scope="text">
          <DataDictFinder dictType="subType" :dictValue="text.type" />
          <DataDictFinder dictType="notNeedSubType" :dictValue="text.type" />
          <DataDictFinder dictType="generalSubType" :dictValue="text.type" />
        </template>
      </a-table-column>

      <a-table-column title="分包方" data-index="companyName" />
      <a-table-column title="分包状态" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="subpackageStatus" :dictValue="text.status" iconType="text" />
        </template>
      </a-table-column>
      <a-table-column title="预算金额(万)" align="right">
        <template slot-scope="text">
          <Money :money="text.planAmount" />
        </template>
      </a-table-column>

      <a-table-column title="操作" align="center">
        <template slot-scope="text">
          <a href="#" @click.prevent="openDetail(text)">查看</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>


<script>
import { fetchSubpackage } from "@/api/project/overview";
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    selectedContractId: {},
  },

  data() {
    return {
      list: [],
      loading: false,

      selectedRowKeys: [],
    };
  },
  mounted() {
    this.getData();
  },

  watch: {
    selectedContractId() {
      this.getData();
    },
  },

  computed: {
    message() {
      let sum = 0;
      this.selectedRowKeys.forEach((id) => {
        const item = this.list.find((item) => item.id === id);
        if (item && typeof item.planAmount === "number") {
          sum += item.planAmount;
        }
      });
      return `已选择 ${this.selectedRowKeys.length} 项，合同金额为 ${parseFloat(
        (sum / 10000).toFixed(2)
      ).toLocaleString()} 万`;
    },
  },

  methods: {
    getData() {
      let id = this.selectedContractId ? this.selectedContractId : "";
      if (!id) return;
      this.loading = true;
      fetchSubpackage({
        id,
      })
        .then((res) => {
          if (Array.isArray(res)) {
            this.list = res;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(keys) {
      this.selectedRowKeys = keys;
    },

    openDetail() {
      this.$router.push(
        "/produce/contract/detail?id=" + this.detail.id + "&active=subpackage"
      );
    },
  },
};
</script>
