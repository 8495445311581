<template>
  <div>
    <div class="title">合同附件</div>

    <a-table bordered :data-source="contractFileList" :pagination="false">
      <a-table-column title="附件名">
        <template slot-scope="text">
          <a v-if="text.path.indexOf('http') !== -1" :href="text.path" download target="_blank">{{ $getFileName(text.path)
                      }}</a>
          <a v-else @click.prevent="clickLink(text.path)">{{
                      fileNameFormatter(text.path)
                      }}</a>
        </template>
      </a-table-column>
    </a-table>

    <div class="title">阶段附件</div>
    <a-table bordered :data-source="progressFileList" :pagination="false">
      <a-table-column title="阶段" width="500px">
        <template slot-scope="text">
          {{ text.title }}
        </template>
      </a-table-column>
      <a-table-column title="附件名">
        <template slot-scope="text">
          <a v-if="text.completePath.indexOf('http') !== -1" :href="text.completePath" download target="_blank">{{
                      $getFileName(text.completePath) }}</a>
          <a v-else @click.prevent="clickLink(text.completePath)">{{
                      fileNameFormatter(text.completePath)
                      }}</a>
        </template>
      </a-table-column>
    </a-table>

    <div class="title">外部采购类合同附件</div>

    <a-table bordered :data-source="subFileList" :pagination="false">
      <a-table-column title="合同名" width="500px">
        <template slot-scope="text">
          {{ text.title }}
        </template>
      </a-table-column>
      <a-table-column title="附件名">
        <template slot-scope="text">
          <a v-if="text.completePath.indexOf('http') !== -1" :href="text.completePath" download target="_blank">{{
                      $getFileName(text.completePath) }}</a>
          <a v-else @click.prevent="clickLink(text.completePath)">{{
                      fileNameFormatter(text.completePath)
                      }}</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import { encode } from "js-base64";
import moment from "moment";
import request from "@/api/request";
function fetchData({ id }) {
  return request({
    url: "/project-service/contract/overview/file/" + id,
    method: "post",
  });
}
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      info: {},
    };
  },
  computed: {
    contractFileList() {
      return Array.isArray(this.info.contractFileList)
        ? this.info.contractFileList.map((path) => {
            return {
              path,
            };
          })
        : [];
    },
    progressFileList() {
      return Array.isArray(this.info.progressFileList)
        ? this.info.progressFileList
        : [];
    },
    subFileList() {
      return Array.isArray(this.info.subFileList) ? this.info.subFileList : [];
    },
  },

  watch: {
    detail() {
      this.getData();
    },
  },

  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const { id } = this.detail;
      if (!id) return;

      fetchData({
        id,
      }).then((res) => {
        if (res) {
          this.info = Object.freeze(res);
        }
      });
    },

    // 金慧的文件名称格式化
    fileNameFormatter(path) {
      const arr = path.split("_");
      if (arr.length >= 2) {
        return arr[1];
      } else {
        return path;
      }
    },

    clickLink(path) {
      const num = path.split("_")[0];
      const time = moment().format("YYYY-MM-DD HH:mm:ss");
      const auth = encode(num + "_" + time);
      const url = `http://218.94.135.30:8025/FileStore/Download.aspx?auth=${auth}`;


      window.open(url)
      const that = this;
      this.$info({
        title: '如果浏览器无法下载，请手动复制下方文件链接',
        content: url,
        okText: '复制',
        onOk() {
          that.$copy(url)
        }
      })

      // const newWindow = window.open("_blank");
      // newWindow.location = url;
      // const hide = this.$message.loading("下载中...");
      // downloadFile({
      //   fileUrl: url,
      // })
      //   .then((blob) => {
      //     saveAs(blob, this.fileNameFormatter(path));
      //   })
      //   .finaly(() => {
      //     hide();
      //   });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-weight: bold;
  color: #1890ff;
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: 8px;
}
</style>