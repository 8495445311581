import request from '../request'

export function fetchList(data) {
    return request({
        url: '/project-service/contract/overview/list',
        method: 'post',
        data
    })
}

export function fetchDetail(data) {
    return request({
        url: `/project-service/contract/overview/detail/${data.id}`,
        method: 'post',
    })
}

export function updateImages(data) {
    return request({
        url: `/project-service/contract/overview/updateBanner`,
        method: 'post',
        data,
    })
}

export function fetchBoard(data) {
    return request({
        url: `/project-service/contract/overview/board/${data.id}`,
        method: 'post',
    })
}

export function fetchSubpackage(data) {
    return request({
        url: 'project-service/projectSub/sub/' + data.id,
        method: 'post',
        data,
    })
}

export function fetchBill(data) {
    return request({
        url: '/project-service/contract/overview/finance/' + data.id,
        method: 'post',
        data,
    })
}

export function fetchProgress({ id }) {
    return request({
        url: '/project-service/progress/listByContract/' + id
    })
}

export function fetchStatistic({ id }) {
    return request({
        url: '/project-service/project/allocate/stat/webAll/' + id
    })
}