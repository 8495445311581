<template>
  <div class="title"><slot /></div>
</template>
<script>
export default {
  props: ["title"],
};
</script>
<style lang="less" scoped>
.title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  position: relative;
}
</style>