<template>
  <div class="cards">
    <div class="card">
      <div class="header">
        <a-icon type="caret-right" style="color: #1890ff; font-size: 18px" />
        <div class="title">项目进度+阶段</div>
      </div>

      <a-skeleton v-if="loading" />
      <div v-else>
        <div class="statistic">
          <div>
            <div class="label">项目阶段</div>
            <div class="value">{{ info.projectProgress ? info.projectProgress.phaseName : "--" }}</div>
          </div>
          <div>
            <div class="label">占比</div>
            <div>
              <span class="value">
                {{
                info.projectProgress ? info.projectProgress.phasePercent : "--"
                }}
              </span>
              <span>%</span>
            </div>
          </div>
          <div>
            <div class="label">阶段进度审核状态</div>
            <div class="value">
              <span v-if="info.projectProgress">
                <DataDictFinder
                  dictType="taskReviewStatus"
                  :dictValue="info.projectProgress.phaseProgressStatus"
                  iconType="text"
                />
              </span>
            </div>
          </div>
          <div>
            <div class="label">区域打分</div>
            <div class="value">
              <span v-if="info.projectProgress">
                {{
                info.projectProgress.score
                ? info.projectProgress.score * 100
                : "--"
                }}
              </span>
            </div>
          </div>
          <div>
            <div class="label">审批人</div>
            <div class="value">
              <span v-if="info.projectProgress">
                {{
                info.projectProgress.phaseProgressAssignerName
                ? info.projectProgress.phaseProgressAssignerName
                : "--"
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="center">
          <Pie
            id="phase-progress"
            title="项目进度"
            :list="[
                          {
                            name: '1',
                            value: typeof info.progress === 'number' ? info.progress : 0,
                          },
                          {
                            name: '2',
                            value:
                              typeof info.progress === 'number' ? 100 - info.progress : 0,
                          },
                        ]"
          />
        </div>
      </div>
    </div>

    <div class="card">
      <div class="header">
        <a-icon type="caret-right" style="color: #1890ff; font-size: 18px" />
        <div class="title">收款项确认进度</div>
      </div>

      <a-skeleton v-if="loading" />

      <div v-else>
        <div class="statistic">
          <div>
            <div class="label">合同金额</div>
            <div>
              <span class="value">
                <Money :money="info.contractAmount" />
              </span>
              <span>万</span>
            </div>
          </div>
          <div>
            <div class="label">进度</div>
            <div>
              <span class="value">
                {{
                typeof info.receiptRatioSum === "number"
                ? info.receiptRatioSum
                : "--"
                }}
              </span>
              <span>%</span>
            </div>
          </div>
        </div>

        <div class="center">
          <Pie
            id="income-progress"
            title="进度"
            :list="[
                          {
                            name: '1',
                            value:
                              typeof info.receiptRatioSum === 'number'
                                ? info.receiptRatioSum
                                : 0,
                          },
                          {
                            name: '2',
                            value:
                              typeof info.receiptRatioSum === 'number'
                                ? 100 - info.receiptRatioSum
                                : 0,
                          },
                        ]"
            :color="['#6AD389', '#F0F2F5']"
          />
        </div>
      </div>
    </div>

    <div class="card">
      <div class="header">
        <a-icon type="caret-right" style="color: #1890ff; font-size: 18px" />
        <div class="title">合同分包情况</div>
      </div>

      <a-skeleton v-if="loading" />

      <div v-else>
        <div class="statistic">
          <div>
            <div class="label">合同金额</div>
            <div>
              <span class="value">
                <Money :money="info.contractAmount" />
              </span>
              <span>万</span>
            </div>
          </div>
          <div>
            <div class="label">分包总额</div>
            <div>
              <span class="value">
                <Money :money="info.subSum" />
              </span>
              <span>万</span>
            </div>
          </div>
          <div>
            <div class="label">设计类-必要分包</div>
            <div>
              <span class="value">
                <Money :money="info.subNeedSum" />
              </span>
              <span>万</span>
            </div>
          </div>
          <div>
            <div class="label">设计类-非必要分包</div>
            <div>
              <span class="value">
                <Money :money="info.subNotNeedSum" />
              </span>
              <span>万</span>
            </div>
          </div>
          <div>
            <div class="label">总承包类分包</div>
            <div>
              <span class="value">
                <Money :money="info.subZcbSum" />
              </span>
              <span>万</span>
            </div>
          </div>
        </div>

        <div class="center">
          <Pie
            id="subpackage1"
            title="分包"
            :list="[
                          {
                            name: '1',
                            value:
                              typeof info.subSum === 'number'
                                ? Math.round((info.subSum / info.contractAmount) * 100)
                                : 0,
                          },
                          {
                            name: '2',
                            value:
                              typeof info.subSum === 'number'
                                ? 100 -
                                Math.round((info.subSum / info.contractAmount) * 100)
                                : 0,
                          },
                        ]"
            :color="['#1890ff', '#F0F2F5']"
          />
          <Pie
            id="subpackage2"
            title="设计类-必要分包"
            :list="[
                          {
                            name: '1',
                            value:
                              typeof info.subNeedSum === 'number'
                                ? Math.round((info.subNeedSum / info.contractAmount) * 100)
                                : 0,
                          },
                          {
                            name: '2',
                            value:
                              typeof info.subNeedSum === 'number'
                                ? 100 -
                                Math.round((info.subNeedSum / info.contractAmount) * 100)
                                : 0,
                          },
                        ]"
            :color="['#6AD389', '#F0F2F5']"
          />
          <Pie
            id="subpackage3"
            title="设计类-非必要分包"
            :list="[
                          {
                            name: '1',
                            value:
                              typeof info.subNotNeedSum === 'number'
                                ? Math.round(
                                  (info.subNotNeedSum / info.contractAmount) * 100
                                )
                                : 0,
                          },
                          {
                            name: '2',
                            value:
                              typeof info.subNotNeedSum === 'number'
                                ? 100 -
                                Math.round(
                                  (info.subNotNeedSum / info.contractAmount) * 100
                                )
                                : 0,
                          },
                        ]"
            :color="['#D3666C', '#F0F2F5']"
          />
          <Pie
            id="subpackage4"
            title="总承包类分包"
            :list="[
                          {
                            name: '1',
                            value:
                              typeof info.subZcbSum === 'number'
                                ? Math.round((info.subZcbSum / info.contractAmount) * 100)
                                : 0,
                          },
                          {
                            name: '2',
                            value:
                              typeof info.subZcbSum === 'number'
                                ? 100 -
                                Math.round((info.subZcbSum / info.contractAmount) * 100)
                                : 0,
                          },
                        ]"
            :color="['#F5A747', '#F0F2F5']"
          />
        </div>
      </div>
    </div>

    <div class="card">
      <div class="header">
        <a-icon type="caret-right" style="color: #1890ff; font-size: 18px" />
        <div class="title">合同净额&进度</div>
      </div>

      <a-skeleton v-if="loading" />
      <div v-else>
        <div class="statistic">
          <div>
            <div class="label">合同净额</div>
            <div>
              <span class="value">
                <Money :money="info.contractAmount" />
              </span>
              <span>万</span>
            </div>
          </div>
          <div>
            <div class="label">开票</div>
            <div>
              <span class="value">
                <Money :money="info.invoiceSum" />
              </span>
              <span>万</span>
            </div>
          </div>
          <div>
            <div class="label">回款</div>
            <div>
              <span class="value">
                <Money :money="info.paymentSum" />
              </span>
              <span>万</span>
            </div>
          </div>
        </div>

        <div class="progress-title">净额</div>
        <div class="left" style="color: #f5a646">
          <div class="progress-bg">
            <div
              class="progress"
              style="background-color: #f5a646"
              :style="{
                              width:
                                getPercent(info.contractAmount, info.contractAmount) + '%',
                            }"
            ></div>
          </div>
          <div class="progress-value">{{ getPercent(info.contractAmount, info.contractAmount) }}</div>
          <div>%</div>
        </div>

        <div class="progress-title" style="margin-top: 8px">开票</div>
        <div class="left" style="color: #1890ff">
          <div class="progress-bg">
            <div
              class="progress"
              style="background-color: #1890ff"
              :style="{
                          width: getPercent(info.invoiceSum, info.contractAmount) + '%',
                        }"
            ></div>
          </div>
          <div class="progress-value">{{ getPercent(info.invoiceSum, info.contractAmount) }}</div>
          <div>%</div>
        </div>

        <div class="progress-title" style="margin-top: 8px">回款</div>
        <div class="left" style="color: #6ad389">
          <div class="progress-bg">
            <div
              class="progress"
              style="background-color: #6ad389"
              :style="{
                          width: getPercent(info.paymentSum, info.contractAmount) + '%',
                        }"
            ></div>
          </div>
          <div class="progress-value">{{ getPercent(info.paymentSum, info.contractAmount) }}</div>
          <div>%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchBoard } from "@/api/project/overview";
import Pie from "./pie.vue";
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    selectedContractId: {}
  },

  components: {
    Pie
  },

  data() {
    return {
      info: {},
      loading: false
    };
  },
  mounted() {
    this.getData();
  },

  watch: {
    selectedContractId() {
      this.getData();
    }
  },

  methods: {
    getData() {
      let id = this.selectedContractId ? this.selectedContractId : "";
      if (!id) return;
      this.loading = true;
      fetchBoard({
        id
      })
        .then(res => {
          this.info = res ?? {};
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getPercent(x, y) {
      if (typeof x === "number" && typeof y === "number" && y !== 0) {
        const num = Math.round((x / y) * 100);
        return num > 100 ? 100 : num;
      } else {
        return "--";
      }
    }
  }
};
</script>


<style lang="less" scoped>
.cards {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 8px;
}

.card {
  border: 1px solid #f0f0f0;
  padding: 12px;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .title {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.statistic {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 12px;

  .label {
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 8px;
  }

  .value {
    font-size: 16px;
    font-weight: bold;
    padding-right: 4px;
  }
}

.progress-title {
  font-size: 13px;
  margin-bottom: 8px;
}

.progress-bg {
  height: 24px;
  flex: 1;
  background-color: #f0f2f5;
}

.progress {
  height: 24px;
}

.progress-value {
  width: 24px;
  text-align: right;
}
</style>