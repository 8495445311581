import { render, staticRenderFns } from "./stage-list.vue?vue&type=template&id=8264e23e&scoped=true"
import script from "./stage-list.vue?vue&type=script&lang=js"
export * from "./stage-list.vue?vue&type=script&lang=js"
import style0 from "./stage-list.vue?vue&type=style&index=0&id=8264e23e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8264e23e",
  null
  
)

export default component.exports