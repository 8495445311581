<template>
  <div>
    <a-alert style="margin-bottom: 8px" v-if="selectedRowKeys.length > 0" :message="message" type="info" show-icon />

    <a-table bordered :data-source="list" :pagination="false" rowKey="id" :row-selection="{
                selectedRowKeys,
                onChange: onChange,
              }">
      <a-table-column title="合同名称" data-index="name" />
      <a-table-column title="合同金额(万)" align="right">
        <template slot-scope="text">
          <Money :money="text.contractAmount" />
        </template>
      </a-table-column>
      <a-table-column title="签订日期" data-index="signDate" align="center" />
      <a-table-column title="操作" align="center">
        <template slot-scope="text">
          <!-- v-if="$getPermission('/produce/contract/detailWithoutStatus')" -->
          <a href="#" @click.prevent="
                                  $router.push('/produce/contract/detail?id=' + text.id)
                                ">查看</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedRowKeys: [],
    };
  },
  computed: {
    list() {
      return Array.isArray(this.detail.contractList)
        ? this.detail.contractList
        : [];
    },
    message() {
      let sum = 0;
      this.selectedRowKeys.forEach((id) => {
        const item = this.list.find((item) => item.id === id);
        if (item && typeof item.contractAmount === "number") {
          sum += item.contractAmount;
        }
      });
      return `已选择 ${this.selectedRowKeys.length} 项，合同金额为 ${parseFloat(
        (sum / 10000).toFixed(2)
      ).toLocaleString()} 万`;
    },
  },
  methods: {
    onChange(keys) {
      this.selectedRowKeys = keys;
    },
  },
};
</script>