<template>
  <div class="major">
    <Title>专业上传文件</Title>

    <Padding />

    <table>
      <tr>
        <th>阶段名称</th>
        <th>专业名称</th>
        <th>文件名</th>
      </tr>
      <template v-if="list.length > 0">
        <template v-for="(item, idx) in list">
          <template v-if="Array.isArray(item.typeFileList)">
            <template v-for="(typeObj, index) in item.typeFileList">
              <template v-if="Array.isArray(typeObj.fileList)">
                <tr
                  v-for="(file, i) in typeObj.fileList"
                  :key="idx + '-' + index + '-' + i"
                >
                  <td
                    :rowspan="item.typeFileList.length"
                    v-if="index === 0 && i === 0"
                  >
                    {{ item.phaseName }}({{ item.phasePercent }}%)
                  </td>
                  <td :rowspan="typeObj.fileList.length" v-if="i === 0">
                    <DataDictFinder
                      dictType="allocateType"
                      :dictValue="typeObj.type"
                    />
                    <DataDictFinder
                      dictType="majorTypes"
                      :dictValue="typeObj.type"
                    />
                    <DataDictFinder
                      dictType="sgphlx"
                      :dictValue="typeObj.type"
                    />
                  </td>
                  <td>
                    <a target="_blank" :href="file.completePath">{{
                      $getFileName(file.completePath)
                    }}</a>
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </template>
      </template>
      <tr v-else>
        <td colspan="3" style="padding: 24px 0">
          <a-empty />
        </td>
      </tr>
    </table>
  </div>
</template>


<script>
import Title from "./title.vue";
export default {
  components: {
    Title,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
table {
  width: 100%;
}
th,
td {
  padding: 8px;
  border: 1px solid #d9d9d9;
  min-width: 100px;
}
th {
  background-color: #f9f9f9;
}
.text-right {
  text-align: right;
}

.small-table {
  background-color: #fff;

  th,
  td {
    border: 1px solid #d9d9d9;
    padding: 2px 4px;
    font-size: 12px;
  }
}
</style>