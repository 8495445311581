<template>
  <div :id="id" style="height: 22vh; width: 22vh"></div>
</template>


<script>
import * as echarts from "echarts/core";
import { TitleComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([TitleComponent, PieChart, CanvasRenderer]);
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: () => [],
    },
    color: {
      type: Array,
      default: () => ["#1890ff", "#F0F2F5"],
    },
    title: {
      type: String,
      default: "",
    },
  },
  watch: {
    list() {
      this.setChart();
    },
  },
  mounted() {
    var chartDom = document.getElementById(this.id);
    this.chart = echarts.init(chartDom);
    this.setChart();
  },

  destroyed() {
    this.chart?.dispose();
  },

  methods: {
    setChart() {
      var option = {
        color: this.color,
        title: {
          text: this.list[0] ? this.list[0].value + "%" : "0%",
          textStyle: {
            color: this.color[0],
            fontSize: 24,
          },
          subtext: this.title,
          subtextStyle: {
            color: "#999",
            fontSize: 12,
          },
          left: "center",
          top: "32%",
        },

        series: [
          {
            name: "项目进度",
            type: "pie",
            radius: ["75%", "90%"],
            avoidLabelOverlap: true,
            top: "5%",
            bottom: "5%",
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: this.list,
          },
        ],
      };

      option && this.chart.setOption(option);
    },
  },
};
</script>