<template>
  <div class="major">
    <Title>阶段上传文件</Title>

    <Padding />

    <table>
      <tr>
        <th>阶段名称</th>
        <th>文件名</th>
      </tr>
      <template v-if="list.length > 0">
        <template v-for="(item, idx) in list">
          <template v-if="Array.isArray(item.fileList)">
            <tr v-for="(file, index) in item.fileList" :key="`${idx}-${index}`">
              <td>
                {{ item.phaseName }}
              </td>
              <td>
                <a :href="file.completePath" download target="_blank">
                  {{ file.name }}
                </a>
              </td>
            </tr>
          </template>
        </template>
      </template>
      <tr v-else>
        <td colspan="2" style="padding: 24px 0">
          <a-empty />
        </td>
      </tr>
    </table>
  </div>
</template>


<script>
import Title from "./title.vue";
export default {
  components: {
    Title,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  methods: {},
};
</script>

<style lang="less" scoped>
table {
  width: 100%;
}
th,
td {
  padding: 8px;
  border: 1px solid #d9d9d9;
  min-width: 100px;
}
th {
  background-color: #f9f9f9;
}
.text-right {
  text-align: right;
}

.small-table {
  background-color: #fff;

  th,
  td {
    border: 1px solid #d9d9d9;
    padding: 2px 4px;
    font-size: 12px;
  }
}
</style>