import { render, staticRenderFns } from "./major-list.vue?vue&type=template&id=119652ac&scoped=true"
import script from "./major-list.vue?vue&type=script&lang=js"
export * from "./major-list.vue?vue&type=script&lang=js"
import style0 from "./major-list.vue?vue&type=style&index=0&id=119652ac&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "119652ac",
  null
  
)

export default component.exports