<template>
  <div class="">
    <div class="left" style="margin-bottom: 12px; margin-top: 8px">
      <span style="width: 3em; margin-right: 4px">子项目</span>
      <a-select
        v-model="selectedProjectId"
        style="width: 320px"
        placeholder="请选择"
      >
        <a-select-option
          v-for="item in projectList ? projectList : []"
          :key="item.id"
          :value="item.id"
          >{{ item.name }}</a-select-option
        >
      </a-select>
    </div>

    <total-output-value :value="item.totalAmount" :cost="item.js3Cost" />

    <Padding size="large" />

    <StageList :list="Array.isArray(item.majorList) ? item.majorList : []" />

    <Padding size="large" />

    <DepartmentList :list="Array.isArray(item.deptList) ? item.deptList : []" />

    <Padding size="large" />

    <MajorList
      :list="Array.isArray(item.subMajorList) ? item.subMajorList : []"
      :majorList="Array.isArray(item.majorList) ? item.majorList : []"
    />

    <Padding size="large" />

    <StaffList
      :list="Array.isArray(item.staffOutputList) ? item.staffOutputList : []"
    />
    <Padding size="large" />

    <MajorFileList
      :list="Array.isArray(item.progressList) ? item.progressList : []"
    />

    <Padding size="large" />

    <FileList
      :list="Array.isArray(item.progressList) ? item.progressList : []"
    />
  </div>
</template>

<script>
import { fetchStatistic } from "@/api/project/overview";

import TotalOutputValue from "./components/total-output-value.vue";
import StaffList from "./components/staff-list.vue";
import StageList from "./components/stage-list.vue";
import DepartmentList from "./components/department-list.vue";
import MajorList from "./components/major-list.vue";
import MajorFileList from "./components/major-file-list.vue";
import FileList from "./components/file-list.vue";

export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    selectedContractId: {},
  },
  components: {
    TotalOutputValue,
    StaffList,
    StageList,
    MajorList,
    MajorFileList,
    FileList,
    DepartmentList,
  },

  computed: {
    projectList() {
      let arr = [];
      if (Array.isArray(this.detail.contractList)) {
        const result = this.detail.contractList.find(
          (item) => item.id === this.selectedContractId
        );
        if (result && Array.isArray(result.projectList)) {
          arr = result.projectList;
        }
      }
      return arr;
    },
  },

  data() {
    return {
      item: {},
      selectedProjectId: null,
    };
  },

  mounted() {
    if (this.projectList.length > 0) {
      this.selectedProjectId = this.projectList[0].id;
    }
    this.getData();
  },

  watch: {
    projectList() {
      if (this.projectList.length > 0) {
        console.log("project list changed");
        this.selectedProjectId = this.projectList[0].id;
      }
    },
    selectedProjectId() {
      this.getData();
    },
  },

  methods: {
    getData() {
      let id = this.selectedProjectId ? this.selectedProjectId : "";
      if (!id) return;
      this.loading = true;
      fetchStatistic({
        id,
      })
        .then((res) => {
          this.item = res ?? {};
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>


<style lang="less" scoped>
.history {
  font-weight: bold;
  font-size: 14px;
}
</style>