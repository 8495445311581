
import request from '../request'

export function uploadFile(data) {
    return request({
        url: '/common-service/file/upload',
        data,
        method: 'POST',
    })
}
